import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { FieldIconProps } from '../../types';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ava-render-icon]',
  template: `
    @if (props) {
      <i nz-icon [nzType]="props?.icon"></i>
    }
  `,
  standalone: true,
  imports: [NzIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RenderIconComponent {
  @Input() props?: FieldIconProps;
  @HostBinding('class') get getClass() {
    return this.props?.cls || '';
  }
}
