import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { FieldH2Props } from '../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ava-render-h2]',
  template: `
    @if (props && props.textBold) {
      <strong>{{ props?.text }}</strong>
    } @else if (props) {
      {{ props?.text }}
    }
  `,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class RenderH2Component {
  @Input() props?: FieldH2Props;
  @HostBinding('class') get getClass() {
    return this.props?.cls || '';
  }
}
