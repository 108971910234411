import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { FieldSpanProps } from '../types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ava-render-span]',
  template: `
    @if (props) {
      {{ props?.text }}
    }
  `,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class RenderSpanComponent {
  @Input() props?: FieldSpanProps;
  @HostBinding('class') get getClass() {
    return this.props?.cls || '';
  }
}
